import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { createNote, updateNote } from 'data/features/notes';
import { NotePayload } from 'data/features/notesTypes';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';

import { NoteFormData } from '../components/NoteForm';
import { getUserRole } from '../helpers';

const useSaveNote = ({
  phaseId,
  teamId,
  dimensionId,
  isOrganizerScreen,
}: {
  phaseId: string;
  teamId: string;
  dimensionId?: string;
  isOrganizerScreen: boolean;
}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();

  const save = async (data: NoteFormData, dirtyFields: Record<string, boolean>) => {
    const payload: NotePayload = {
      id: data.id,
      role: getUserRole(isOrganizerScreen),
      updated_at: data.updated_at,
      type: data.internal ? 'internal' : 'shared',
      content: data.content,
    };

    if (payload.id) {
      const noteType = data.internal ? 'internal' : 'shared';
      payload.content = dirtyFields?.content ? data.content : undefined;
      payload.type = dirtyFields?.internal ? noteType : undefined;
    } else {
      payload.dimensionId = dimensionId;
    }

    const method = payload.id ? updateNote : createNote;
    const result = showErrorOnRejectedWithValue(
      await dispatch(
        method({
          note: payload,
          phaseId,
          teamId,
          autosaved: data.autosaved,
          isAddForm: data.isAddForm,
        }),
      ),
    );
    return result;
  };

  return save;
};

export default useSaveNote;
